import moment from "moment"

export default (maker, cart) => {
  const today = moment()
  try {
    const deliveryDays = [...Array(90)].map((_, i) => {
      const next = moment(today).add(i, "days")
      const sameDay = today.isSame(next) && next.isBefore(moment().hour(19))
      const weekday = parseInt(next.isoWeekday())
      const number = next.format("DD")
      const day = next.format("dddd")
      const month = next.format("MMMM")
      const year = next.format("YYYY")
      const full = next.format("YYYY-MM-DD")
      const string = next.format("dddd Do MMMM YYYY")
      const specialDeliveryDay =
        maker.specialDeliveryDays?.find(
          (item) => moment(item.date).format("YYYY-MM-DD") === full
        ) || false
      const scheduleItem =
        specialDeliveryDay ||
        maker.deliveryDays?.find((item) => item.name === day)
      const dayDiff = scheduleItem?.additional_days || false
      const cutOffTime = scheduleItem?.cut_off_time || false
      const orderDay = next.subtract(dayDiff, "days")
      const validTime =
        parseInt(scheduleItem?.cut_off_time.slice(0, 2)) >
        parseInt(moment().hour())
      const validDay =
        today.isBefore(orderDay, "day") ||
        (today.isSame(orderDay, "day") && validTime)
      const available =
        (sameDay && maker.hasSameDayProducts) ||
        (validDay && scheduleItem?.is_active)

      const openTime = scheduleItem.open_time?.slice(0, 2) || "07"
      const closeTime = scheduleItem.close_time?.slice(0, 2) || "19"

      return {
        weekday,
        number,
        day,
        month,
        year,
        full,
        string,
        cutOffTime,
        orderDay,
        available,
        validDay,
        validTime,
        dayDiff,
        sameDay,
        openTime,
        closeTime,
      }
    })

    const nextAvailable = deliveryDays?.find(
      (day) => day.available && !day.sameDay
    )

    const updatedMaker = {
      ...maker,
      next_available: nextAvailable,
      delivery_days: deliveryDays,
    }

    let updatedCart
    let cartDateNoLongerAvailable = false

    if (cart && maker.id === cart.maker.id) {
      const matchingDate = updatedMaker.delivery_days.find(
        (deliveryDay) =>
          deliveryDay.full === cart.date.full && deliveryDay.available === true
      )

      if (matchingDate) {
        updatedCart = {
          ...cart,
          date: { ...cart.date, ...matchingDate },
          maker: updatedMaker,
        }
      } else {
        updatedCart = {
          ...cart,
          date: { ...cart.date, ...nextAvailable },
          maker: updatedMaker,
        }
        cartDateNoLongerAvailable = true
      }
    }

    return {
      maker: updatedMaker,
      cart: updatedCart,
      cartDateNoLongerAvailable,
    }
  } catch (error) {
    return {
      maker: {
        ...maker,
        next_available: false,
        delivery_days: false,
      },
      cart: undefined,
      cartDateNoLongerAvailable: false,
    }
  }
}
